@tailwind base;
@tailwind components;
@tailwind utilities;



body {
  background-image: url('../src/assets/images/mainbg.png');
  height: 100%;
  background-repeat: repeat;
  background-color: black;
  padding-top: 88.87px;
}


@layer utilities {

  /* custom scroll */
  .custom-scroll::-webkit-scrollbar-track {
    @apply bg-black/80 rounded;
  }
  .custom-scroll::-webkit-scrollbar {
    @apply bg-gray-200 w-[6px] rounded;
  }
  .custom-scroll::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(165, 139, 139, 0.3);
    @apply bg-[#2972D7] rounded w-[4px];
  }
  /* Hide scrollbar for Webkit browsers (Chrome, Safari) */
  .scrollbar-hidden::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for Firefox */
  .scrollbar-hidden {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  html {
    @apply scroll-smooth scroll-pt-[110px]
  }

  .custom-glow {
    filter: drop-shadow(0px 0px 20px #2972D7);
    text-shadow: 0 0 13px #2972D7;
  }

  .custom-glow-icons {
    transition: all 0.3s ease-in-out;
  }

  .custom-glow-icons:hover {
    filter: drop-shadow(0 0 10px #2972D7);
  }

  .shadow-custom-glow:hover {
    filter: drop-shadow(0 0 8px #2972D7);
  }



  /* new font gothic pro */

  /* @font-face {
    font-family: 'gothicproMedium';
    src: url('./assets/fonts/BankGothicPro-Medium.otf');
    font-weight: 500;
  } */

  @font-face {
    font-family: 'gothicproLight';
    src: url('./assets/fonts/BankGothicPro-Light.otf');
    font-weight: 300;
  }



  @font-face {
    font-family: 'gothicproMedium';
    src: url('./assets/fonts/BankGothicPro-Medium.otf');
    font-weight: 500;
  }

  /* new font gothic pro */












  .container {
    @apply max-w-[1240px] w-full mx-auto px-5
  }
/* 
  @font-face {
    font-family: 'gothic';
    src: url('./assets/fonts/BankGothicLightRegular.otf');
    font-weight: normal;
    font-style: normal;
  } */

  /* @font-face {
    font-family: 'gothicMdbt';
    src: url('./assets/fonts/BankGothicMdBT.ttf');
  } */


  /* @font-face {
    font-family: 'gothiclight';
    src: url('./assets/fonts/BankGothicLightRegular.otf');
  } */

  @font-face {
    font-family: 'inter';
    src: url('./assets/fonts/Inter_28pt-Regular.ttf');
    font-weight: normal;
    font-style: normal;
  }

  .hovereffect {
    @apply shadow-[-5px_1px_275px_0px_rgba(41, 114, 215, 0.68)];
  }

  .text_shadow {
    /* text-shadow: 0px 0px 20px #2972D7; */
    text-shadow: 0px 0px 10px #2972D7;

  }

  @keyframes zoom-in-zoom-out {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.5, 1.5);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .footerIconsAnimation {
    @apply transition-all duration-300 hover:translate-y-[-5px] hover:scale-[1.1] hover:rotate-6;
  }

  .HeaderNavAnimation {
    @apply relative after:content-[''] after:absolute after:bottom-0 after:h-[1px] after:bg-white after:w-0 after:left-[50%] hover:after:w-full after:transition-all hover:after:left-0 after:duration-200
  }

  .clip-top-left {
    clip-path: polygon(0 0, 100% 0, 0 100%);
  }

  .clip-top-right {
    clip-path: polygon(100% 0, 100% 100%, 0 0);
  }

  .clip-bottom-left {
    clip-path: polygon(0 0, 0 100%, 100% 100%);
  }

  .clip-bottom-right {
    clip-path: polygon(100% 100%, 100% 0, 0 100%);
  }

  .btn_brown::before {
    background-color: #000b16;
  }
}